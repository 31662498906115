@import "./variables.less";

*{
  box-sizing: border-box;
  font-family: @fontFamily;
}

html{
  scroll-behavior: smooth;
}

html, body, div, span, p{
  margin: 0;
  padding: 0;
  font-size: @baseFontSize;
  line-height: @baseLineHeight;
}

.c-blue{
  color: @blueGalicine;
}
.c-w{
  color: #fff;
}
.c-b{
  color: #212529;
}

.grecaptcha-badge{
  display: none;
}